import React, { useContext } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

import Slider from '@components/slider/Index';
import NextButton from '@components/slider/NextButton';
import PrevButton from '@components/slider/PrevButton';

import TemplateSlide from './TemplateSlide';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import IVButton from '@invideoio/web-shared/components/Button';
import { RedirectContext, StateContext } from '../../store/store';
import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';
import { AUTH_TYPE } from '../../constants/homepage';
import { SimpleHeadingType, SingleSlideProps } from '../../types';
import {
  ClickCtaUxSource,
  ClickCtaType,
  AnalyticsProductType,
} from 'src/types/analytics.types';
import { BaseAppType } from '@constants/common';

interface TemplatesProps {
  platform: PlatformType;
  videoWallSlide_1: SingleSlideProps;
  videoWallSlide_2: SingleSlideProps;
  videoWallSlide_3: SingleSlideProps;
  videoWallSlide_4: SingleSlideProps;
  heading: SimpleHeadingType;
  CTAUrl: string;
  buttonText: string;
  layoutV3?: boolean;
}

function VideoWallTemplates(props: TemplatesProps) {
  const {
    platform,
    videoWallSlide_1,
    videoWallSlide_2,
    videoWallSlide_3,
    videoWallSlide_4,
    heading,
    CTAUrl,
    buttonText,
    layoutV3,
  } = props;

  const { handleClick } = useContext(RedirectContext);
  const { baseAppType } = useContext(StateContext);
  const onClick = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickSignUp, {
      btnPosition: 'Video Wall Template Section',
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.VideoWallTemplateSelection,
        type: ClickCtaType.ClickSignup,
        feature_utm: 'na',
        product: AnalyticsProductType.AI,
      },
    });
    window.open(CTAUrl, '_self');
  };

  return (
    <section
      id="iv-templates"
      className={ivclass(
        'xl:iv-pt-60 iv-pt-60 sm:iv-pt-60 iv-overflow-x-hidden',
      )}
    >
      <div className={ivclass('iv-container iv-mx-auto')}>
        <div
          className={ivclass(
            'iv-grid',
            'xl:iv-pb-75 iv-pb-50 sm:iv-pb-30',
            'iv-pl-60 lg:iv-pl-75 sm:iv-pl-[45px]',
            layoutV3 && 'iv-text-center',
            layoutV3 ? 'sm:iv-px-20' : 'sm:iv-pl-[45px]',
          )}
        >
          <h2
            className={ivclass(
              'iv-text-grey-100',
              'xl:iv-text-heading-4xl iv-text-heading-2xl sm:iv-text-heading-2xs',
              'xl:iv-m-heading-4xl iv-m-heading-2xl sm:iv-m-heading-2xs',
              'iv-font-extrabold',
            )}
          >
            {heading.plainHeading}
            <span className={ivclass('iv-title-gradient', 'sm:iv-block')}>
              {heading.highlightedHeading}
            </span>
          </h2>
        </div>
      </div>
      <Slider
        sliderWrapperClasses={ivclass('lg:iv-ml-40 md:iv-ml-25 sm:iv-ml-20')}
        childrenWrapperClass={ivclass(
          platform !== PlatformType.Desktop && 'md:iv-gap-20 sm:iv-gap-[16px]',
          platform !== PlatformType.Desktop &&
            'md:iv-pl-10 md:iv-pr-10 sm:iv-pr-8 sm:iv-pl-8',
        )}
        renderButtons={(
          scrollPrev,
          scrollNext,
          prevBtnEnabled,
          nextBtnEnabled,
        ) =>
          platform === PlatformType.Mobile ||
          platform === PlatformType.Tablet ? (
            <React.Fragment />
          ) : (
            <React.Fragment>
              <PrevButton
                clickHandler={scrollPrev}
                isEndReached={prevBtnEnabled}
              />
              <NextButton
                clickHandler={scrollNext}
                isEndReached={nextBtnEnabled}
              />
            </React.Fragment>
          )
        }
      >
        <TemplateSlide
          slide={videoWallSlide_1}
          platform={platform}
          isVideoWall={true}
        />
        <TemplateSlide
          slide={videoWallSlide_2}
          platform={platform}
          isVideoWall={true}
        />
        <TemplateSlide
          slide={videoWallSlide_3}
          platform={platform}
          isVideoWall={true}
        />
        <TemplateSlide
          slide={videoWallSlide_4}
          platform={platform}
          isVideoWall={true}
        />
      </Slider>
      <div className={ivclass('iv-pt-60 sm:iv-pt-30')}>
        <IVButton
          className={ivclass(
            'iv-flex',
            'iv-items-center',
            'iv-cursor-pointer',
            'iv-mx-auto',
            'iv-px-25 sm:iv-px-[14px]',
            'iv-h-52 sm:iv-h-28',
            'iv-border-2 iv-border-grey-85 hover:iv-border-grey-100 hover:iv-bg-grey-100 iv-transition',
            'iv-rounded-full sm:iv-rounded-m',
            'iv-text-grey-85 hover:iv-text-grey-0',
            'iv-text-body-2xl sm:iv-text-body-m',
            'iv-font-semibold',
          )}
          onClick={onClick}
        >
          {buttonText}
        </IVButton>
      </div>
    </section>
  );
}

export default VideoWallTemplates;
